<template>
    <div id="app">
        <template v-if="$route.name == 'PageNotFound'">
            <router-view></router-view>
        </template>
        <template v-else-if="$route.path.indexOf('/directory/') >= 0">
            <app-extension-header v-if="showHeader"></app-extension-header>
            <div class="app-sidebar-right" id="wrapper">
                <app-extension-sidebar v-if="showHeader"></app-extension-sidebar>
                <router-view></router-view>
            </div>
        </template>
        <template v-else>
            <app-header v-if="showHeader"></app-header>
            <div class="app-sidebar-right" id="wrapper">
                <sidebar v-if="showHeader"></sidebar>
                <router-view></router-view>
            </div>
        </template>
    </div>
</template>

<script>
    import "@babel/polyfill";
    import Header from '@/components/partials/Header';
    import Sidebar from '@/components/partials/Sidebar';
    import ExtensionHeader from '@/components/partials/ExtensionHeader';
    import ExtensionSidebar from '@/components/partials/ExtensionSidebar';
    export default {
        components: {
            'app-header':Header,
            'sidebar':Sidebar,
            'app-extension-header': ExtensionHeader,
            'app-extension-sidebar': ExtensionSidebar
        },
        name: 'App',
        data: () => ({
            showHeader:true,
        }),
        mounted: function() {
            var self = this;

            if(window.location.pathname == "/") {
                self.showHeader = false;
                $(".app-sidebar-right").css("padding-left","0px");
                $(".app-sidebar-right").css("margin-top","0px");
                $(".app-sidebar-right").css({"-webkit-transition":"all 0.0s ease","-moz-transition":"all 0.0s ease","-o-transition":"all 0.0s ease","transition":"all 0.0s ease"});
            } else {
                self.showHeader = true;
            }
        }
    }
</script>