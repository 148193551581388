import EventBus from "../components/event-bus";
import {HTTP} from '../http-common';
import moment from 'moment';
var self;

export const commonMixins = {

    data: () => ({
            colors: ['#ffbb44', '#098de1', '#154174', '#c3202b', '#607d8b', '#444444', '#446626', '#cc9636', '#23727b'],
            menuData: [
                {

                }
            ],
            features: [
                {

                }
            ],
            menuslug: "",
            pageType: "",
            currentTab: "",
            menuParentSlug: "",
            systemYear: "2019",
            isPageLoading: true,
            hasMenuViewPermission: false,
            hasMenuEditPermission: false
        }),
    mounted: function () {

        self = this;

        //check token if user came from rental
        let token = this.$route.query.token;
        if (token != undefined) {
            localStorage.setItem("tkn", token);
        }

        /* if Acces Token is not exits redirect to login page. */
        if (localStorage.getItem("tkn") == "" || localStorage.getItem("tkn") == null) {
            let current_build = window.localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
            // self.$router.push("/");
        }
        //$("html, body").animate({scrollTop: 0}, 10);
    },
    created() {
        /*console.log("mixin created");*/
    },
    beforeMount() {
        self = this;
    },
    watch: {
        menuslug: function (menuslug) {
            let self = this;
            setTimeout(function () {
                self.menuActive(menuslug);
            }, 1000);
            //self.$store.dispatch('getRole');
        }
    },

    methods: {
        getMenuDetails: function (slug) {
            let self = this;
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tkn")
                },
                params: {
                    'slug': slug,
                }
            };
            self.showLoader();
            self.isPageLoading = true;
            HTTP.get("get_menu_details", config)
                    .then(function (response) {
                        self.hasMenuViewPermission = self.hasMenuEditPermission = false;

                        if (response.data.code == 200) {
                            self.currentTab = "";
                            self.menuData = response.data.content.menu_details;

                            if(response.data.content.menu_details && response.data.content.menu_details.is_view_allow == 1) {
                                self.hasMenuViewPermission = true;
                            }
                            if(response.data.content.menu_details && response.data.content.menu_details.is_edit_allow == 1) {
                                self.hasMenuEditPermission = true;
                            }

                            self.menuslug = self.menuData.slug;
                            self.menuParentSlug = (self.menuData.parent_slug != null) ? self.menuData.parent_slug : self.menuData.slug;
                            self.pageType = self.menuData.page_type.split(',').sort();

                            /**
                             * If the previous route is 'CommonPostDetail', 'AttachmentAddForm', etc. it uses the given 'currentTab'. 
                             * Otherwise, it matches 'pastTab' to a feature name, or defaults to 'Attachments' if no match is found.
                             */
                            const routeData = self.$store.state.routeData || {};
                            const { prevRoute, currentTab } = routeData;
                            if(prevRoute && (prevRoute == 'CommonPostDetail' || prevRoute == 'DiscussionAddForm' || prevRoute == 'AttachmentAddForm' || prevRoute == 'ImageAddForm')) {
                                self.currentTab = self.removeSpacesString(currentTab);
                            } else {
                                if (self.menuData.features.length) {
                                    $.each(self.menuData.features, function (i) {
                                        if(self.pastTab == self.removeSpacesString(self.menuData.features[i].name)){
                                            self.currentTab = self.pastTab; 
                                        }
                                        if (self.currentTab == "") {
                                            self.currentTab = self.removeSpacesString(self.menuData.features[i].name);
                                        }
                                    });
                                } else {
                                    self.currentTab = self.removeSpacesString("Attachments");
                                }
                            }
                            if (typeof (self.$route.query.group_id) != 'undefined' && self.$route.query.group_id != 0 && self.$route.query.group_id != '') {
                                self.currentTab = self.removeSpacesString("Attachments");
                            }
                            self.changeTab(self.currentTab);
                            // Getting User Roles with Menu Details
                            self.$store.commit('changeUserRole', response.data.content.roles.id);
                            localStorage.setItem("user_role", response.data.content.roles.id);
                            self.systemYear = response.data.content.system_year;
                            EventBus.$emit("systemYear", true, response.data.content.system_year);
                            if($(window).width() < 1100){
                                $(".app-sidebar-right").removeClass("toggled");
                                $(".app-sidebar-right").removeClass("toggled-2");
                            }
                            /* Build version checking start*/
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build = response.data.content.build;
                            console.log('current_build ' + current_build);
                            console.log('latest_build ' + latest_build);
                            if(current_build == null || current_build == 'null') {
                                console.log("Set in 1st If...");
                                localStorage.setItem("build_version", response.data.content.build);
                            } else if (current_build != undefined && current_build == '') {
                                console.log("Set in 2nd If...");
                                localStorage.setItem("build_version", response.data.content.build);
                            } else if(current_build != latest_build){
                                console.log("reload...");
                                localStorage.setItem("build_version", response.data.content.build);
                                console.log("Here...");
                                location.reload(true);
                            } else {
                                console.log("Else...");
                            }
                            /* Build version checking end*/
                        }

                        self.hideLoader();
                        self.isPageLoading = false;
                    }).catch(function (err) {
                        self.hideLoader();
                        self.isPageLoading = false;
                        self.catchHandler(err, function () {});
            });
            self.menuActive(slug);
        },
        getFeatures: function () {

            let self = this;
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tkn")
                },
            };
            HTTP.get("get_features", config)
                    .then(function (response) {
                        if (response.data.code == 200) {
                            self.features = response.data.content;
                        }
                    }).catch(function (err) {
                        self.catchHandler(err, function () {});
            });
        },
        /* Get User Role Permission */
        /*
         getRole: function () {
         var self = this;
         var config = {
         headers: {Authorization: "Bearer " + localStorage.getItem("tkn")}
         };
         HTTP.get("user/role", config)
         .then(function (response) {
         if (response.data.status == 'success') {
         localStorage.setItem("user_role", response.data.content.roles.id);
         self.systemYear = response.data.content.system_year;
         return response.data.content.roles.id;
         }
         })
         .catch(function (err) {
         if (err.response.status == 0) {
         self.error = "Remote server can not be reachable";
         } else {
         //redirect to login page if user not authenticated
         if (err.response.status == 401) {
         localStorage.removeItem('user_obj');
         localStorage.removeItem('api_token');
         self.$router.push("/");
         }
         }
         });
         },
         */
        //make sentence first letter capital
        ucString: function (data) {
            let value = data.replace(/_/g, " ");
            let upp = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase();
            });
            return upp;
        },
        menuActive: function (slug) {
            /* console.log(slug); */
        //setTimeout(function () {
            $(".menuCommon").removeClass("active");
            $(".subMenuLi").removeClass("active");
            if(slug && typeof slug !== 'undefined') {
                $("." + slug).addClass("active");
                // $("." + slug).parent().show();

                // Find the parent menu of the selected submenu and add 'active' class to it
                let parentMenu = $("." + slug).closest('.menuCommon');
                if (parentMenu.length) {
                    parentMenu.addClass("active");
                    
                    // Ensure the submenu is displayed correctly before sliding down
                    let submenu = parentMenu.children('ul.tree');
                    submenu.stop(true, true).slideDown('normal'); // Apply the slideDown effect to show the submenu tree
                }
            }
            $('#menu ul').children('.current').parent().show();
            //#menu li a,
             $('#menu li .menu-arrow').click(
                function() {
                    //var checkElement = $(this).next();
                    let checkElement = $(this).parents('.menuCommon').children('ul.tree');
                    if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                        let submenutree = $(this).parents('.menuCommon').children('ul.tree').children('.menuCommon').find('.archive-menu-arrow');
                        if(submenutree.length > 0){
                            $('.archive-menu-arrow').click(function () {
                                let checkElement2 = $(this).parents('.archived-menu').children('ul.tree');
                                if ((checkElement2.is('ul')) && (checkElement2.is(':visible'))) {
                                    return false;
                                }
                                if ((checkElement2.is('ul')) && (!checkElement2.is(':visible'))) {
                                    $('.archived-menu ul:visible').slideUp('normal');
                                    checkElement2.slideDown('normal');
                                    return false;
                                }
                            });
                        }
                        return false;
                    }

                    // Helper function to close open menus except the clicked one
                    let menusToClose = ['.forms', '.ext-dir', '.archived'];
                
                    // Loop through each menu type and close if it's open
                    menusToClose.forEach(function(menuClass) {
                        let menu = $(menuClass);
                        if (menu.hasClass('active') && !menu.is($(this).parents('.menuCommon'))) {
                            menu.removeClass('active').children('ul.tree').slideUp('normal');
                        }
                    });

                    if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                        $('#menu ul:visible').slideUp('normal');
                        checkElement.slideDown('normal');
                        return false;
                    }
                }
            ); 
            /*$('#menu li .menu-arrow').click(
                function() {
                    var checkElement = $(this).next();
                    if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                        return false;
                    }
                    if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                        $('#menu ul:visible').slideUp('normal');
                        checkElement.slideDown('normal');
                        return false;
                    }
                }
            );*/

        //}, 1000);
        },
        changeTab: function (tab) {
            let self = this;
            self.currentTab = tab;
            if (tab == "discussion") {
                $(".btnDiscussion").removeClass("btn-outline-primary");
                $(".btnDiscussion").addClass("btn-primary");

                $(".btnAttachment").removeClass("btn-primary");
                $(".btnAttachment").addClass("btn-outline-primary");

                $(".btnIframeURL").removeClass("btn-primary");
                $(".btnIframeURL").addClass("btn-outline-primary");

                $(".btnImages").removeClass("btn-primary");
                $(".btnImages").addClass("btn-outline-primary");
            } else if (tab == "iframeurl") {
                $(".btnIframeURL").removeClass("btn-outline-primary");
                $(".btnIframeURL").addClass("btn-primary");

                $(".btnDiscussion").removeClass("btn-primary");
                $(".btnDiscussion").addClass("btn-outline-primary");

                $(".btnAttachment").removeClass("btn-primary");
                $(".btnAttachment").addClass("btn-outline-primary");

                $(".btnImages").removeClass("btn-primary");
                $(".btnImages").addClass("btn-outline-primary");
            } else if (tab == "images") {
                $(".btnImages").removeClass("btn-outline-primary");
                $(".btnImages").addClass("btn-primary");

                $(".btnDiscussion").removeClass("btn-primary");
                $(".btnDiscussion").addClass("btn-outline-primary");

                $(".btnAttachment").removeClass("btn-primary");
                $(".btnAttachment").addClass("btn-outline-primary");

                $(".btnIframeURL").removeClass("btn-primary");
                $(".btnIframeURL").addClass("btn-outline-primary");
            } else {
                $(".btnAttachment").removeClass("btn-outline-primary");
                $(".btnAttachment").addClass("btn-primary");

                $(".btnDiscussion").removeClass("btn-primary");
                $(".btnDiscussion").addClass("btn-outline-primary");

                $(".btnIframeURL").removeClass("btn-primary");
                $(".btnIframeURL").addClass("btn-outline-primary");
                
                $(".btnImages").removeClass("btn-primary");
                $(".btnImages").addClass("btn-outline-primary");
            }
            EventBus.$emit("currentTab", tab);
        },
        utcToLocal: function (dateTime, format) {
            var self = this;
            var local = moment.utc(dateTime).local().format(format);
            return local;
        },
        formatDateTime: function (dateTime, format) {
            var self = this;
            var dateTime = new Date(dateTime);
            var dateTime = moment(dateTime).format(format);
            return dateTime;
        },
        gerRandomColor: function () {
            var self = this;
            var item = self.colors[Math.floor(Math.random() * self.colors.length)];
            return item;
        },
        showSAlert: function (message, type, callback) {
            this.$swal.fire(
                    message,
                    '',
                    type
                    );
            //callback("message");
        },
        catchHandler: function (err, callback) {
            var self = this;
            switch (err.response.status) {
                case 0:
                    this.showSAlert('Remote server can not be reachable', 'error', callback);
                case 400:
                    this.showSAlert(err.response.data.message, 'error', callback);
                    break;
                case 401:
                    let current_build = window.localStorage.getItem("build_version");
                    localStorage.clear();
                    localStorage.setItem("build_version", current_build);
                    window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/logout?redirect_url=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
                    // window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/login?redirect=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
                    break;
                case 404:
                    this.showSAlert(err.response.data.message, 'error', callback);
                    break;
                case 409:
                    this.showSAlert(err.response.data.message, 'error', callback);
                    break;
            }
        },
        removeSpacesString: function (srting) {
            return srting.replace(/\s/g, '').toLowerCase();
        },
        stringTrim: function (string, length) {
            length = (typeof length !== 'undefined') ? length : 20;
            var string1 = (string.length > length) ? string.substring(0, length) + '...' : string;
            return string1;
        },
        showLoader: function () {
            $(".loading-container").show();
        },
        hideLoader: function () {
            //setTimeout(function () {
                $(".loading-container").hide();
            //}, 1700);
        },
        firstLoadMenu: function () {
            var self = this;
            $('#menu ul').hide();
        },
        enableDisableButton: function (identifire,is_disable,is_id) {
            if(is_id == true){
                $('#'+identifire).attr("disable", is_disable);
            } else {
                $('.'+identifire).attr("disable", is_disable);
            }
        },
        getRecords: function (currentPage1, perPage1, totalRecords1) {
            let serverPage = 0;
            let perPage = 0;
            let totalRecords = 0;
            var firstLoad = true;
            var firstRecord = 0;
            var lastRecord = 0;
            if (perPage1 == "All") {
                if (totalRecords1 == 0) {
                    firstRecord = 1;
                } else {
                    firstRecord = 1;
                }
                lastRecord = totalRecords1;
            } else {
                serverPage = Number(currentPage1);
                perPage = Number(perPage1);
                totalRecords = Number(totalRecords1);
                firstLoad = true;
                firstRecord = 0;
                lastRecord = 0;

                var page = serverPage;
                for (var i = 1; i <= page; i++) {
                    if (firstLoad == true) {
                        firstRecord = lastRecord + 1;
                        lastRecord = perPage;
                        firstLoad = false;
                    } else {
                        firstRecord = lastRecord + 1;
                        lastRecord = (perPage * i);
                    }
                }

                if (totalRecords != 0) {
                    if (lastRecord > totalRecords) {
                        lastRecord = totalRecords;
                    }
                } else if (totalRecords == 0) {
                    firstRecord = 0;
                    lastRecord = 0;
                }
            }
            return firstRecord + " - " + lastRecord;
        },
        validEmail : function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        trimAndCheckValidEmail: function(email) {
            var trimEmail = email.trim();
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(trimEmail);
        },
        addNewLineInColumn: function(value) {
            if(value != null && value != "") {
                let result = value.replaceAll(",", "<br/>");
                return result;
            } else {
                return "";
            }
        },
        addNewLineInLocationColumn: function(value) {
            if(value != null && value != "") {
                let result = value.replaceAll("|", "<br/>");
                return result;
            } else {
                return "";
            }
        },
        getAllPermissions: function () {
            var self = this;
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("tkn")}
            };
            HTTP.get('get-all-permissions', config)
                .then(response => {
                    EventBus.$emit('all-permissions', false, response.data.content);
                })
                .catch(function (err) {
                    console.log(err);
                    self.catchHandler(err, function () {});
                });
        },
        storeFilters: function (listType, pageFilters, clear) {
            if (typeof clear === 'undefined' || clear === null) {
                clear = false;
            }
            var getfilters = {};
            if (!clear) {
                var filterObj = localStorage.getItem("filters");
                if (filterObj != null) {
                    getfilters = JSON.parse(filterObj);
                }
            }
            getfilters[listType] = pageFilters;
            window.localStorage.setItem("filters", JSON.stringify(getfilters));
        },
        loadFilters: function (listType, columns, serverParams, listClass) {
            if (typeof listClass === 'undefined' || listClass === null) {
                listClass = '';
            }
            var appliedFilters = localStorage.getItem('filters');
            if (appliedFilters != null) {
                appliedFilters = JSON.parse(appliedFilters);
                if (appliedFilters[listType] != null) {
                    serverParams.sort = appliedFilters[listType].sort;
                    serverParams.page = appliedFilters[listType].page;
                    serverParams.perPage = appliedFilters[listType].perPage;
                    serverParams.setCurrentPage = appliedFilters[listType].setCurrentPage;
                    serverParams.columnFilters = appliedFilters[listType].columnFilters;
                    $.map(appliedFilters[listType].columnFilters, function (val, key) {
                        $.each(columns, function (i) {
                            if (columns[i].field == key && val != '') {
                                let el = $('.' + listClass + ' *[placeholder="' + columns[i].filterOptions.placeholder + '"]');
                                el.val(val);
                                columns[i].filterOptions.filterValue = val;
                            }
                        });
                    });
                }
            }
        },
        loadAllFilter: function(listType, listClass) {
            if (typeof listClass === 'undefined' || listClass === null) {
                listClass = '';
            }
            var appliedFilters = localStorage.getItem('filters');
            if (appliedFilters != null) {
                appliedFilters = JSON.parse(appliedFilters);
                if (appliedFilters[listType] != null) {
                    return appliedFilters[listType];
                }
            }
            return [];
        },
        getUserPermissions: function () {
            var self = this;
            var userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
            }
            var fields = {
                user_id: userJson['id'],
            };
            var config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("tkn")}
            };
            HTTP.post('get-login-id-permissions', fields, config)
                .then(response => {
                    EventBus.$emit('permissions', false, response.data.content.user.permissions);
                    if(response.data.company != null) {
                        EventBus.$emit('contact', false, response.data.company.alias, localStorage.getItem("tkn"));
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    self.catchHandler(err, function () {});
                });
        },
        validContactNumber : function (number) {
            return /^[0-9 -()+,-]*$/.test(number);
        },
        checkContactNoLength : function (val) {
            if (val.length > 13) {
                return false;
            } else {
                return true;
            }
        },
        setWidthToContactContainer: function (time) {
            time = (time === null || typeof (time) === "undefined" || time == '') ? 300 : time;
            var screenWid = $(window).width() + 10;
            if (screenWid > 767) {
                setTimeout(function () {
                    var pageContent = $('.admin-page-content');
                    var pageContentSidePad = parseInt(pageContent.css('padding-right')) + parseInt(pageContent.css('padding-left'));
                    var contactDetail = $(".page-sidebar").outerWidth();
                    var sideMenu = $("#sidebar-wrapper").outerWidth();
                    if ($('.app-sidebar-right').attr('class').indexOf('toggled') != -1) {
                        sideMenu = 50;
                    }
                    $(".contact-container").width(Math.ceil(screenWid - pageContentSidePad - contactDetail - sideMenu));
                }, time);
            }
        },
        menuTtoggleDesktop: function (from) {
            var self = this;
            $(".app-sidebar-right").toggleClass("toggled-2");
            self.setWidthToContactContainer(550);
        },
        menuTtoggleMobile: function (from) {
            var self = this;
            $(".app-sidebar-right").toggleClass("toggled");
            $('#menu ul').hide();
            self.setWidthToContactContainer(550);
        },
        getFirstChar: function (string) {
            if (string != '') {
                let getName = string.charAt(0);
                return getName.toUpperCase();
            } else {
                return 'V';
            }
        },
        downloadFile: function (href) {
            var fileNameIndex = href.lastIndexOf("/") + 1;
            var filename = href.substr(fileNameIndex);
            if ($('#DynDownloadAnchor').length) {
                $('#DynDownloadAnchor').remove();
            }
            var anchor = document.createElement('a');
            anchor.id = "DynDownloadAnchor";
            anchor.style.display = "none";
            anchor.href = href;
            anchor.target = "_blank";
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
        },
        getSignedUrlPath(file){
            if(file && typeof file !== 'undefined') {
                let config = {
                    headers: { 
                        Authorization: "Bearer " + localStorage.getItem("tkn") 
                    },
                    params: {
                        file: file,
                    }
                };
                HTTP.get("getsignedurlpath", config)
                    .then(function (response) {
                        window.open(response.data.file_path);
                    })
                    .catch(function (err) {
                    });
            }
        },
        /**
         * Formats a given title into a slug
         * @param {string} title - Title of the menu
         * @returns {string} The slugified version of the title
         */
        slugify(title) {
            return title.toLowerCase().trim()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '');            // Trim - from end of text
        },
        urlValidation: function(url) {
            if(url != '' && url != null){
                var res = url.match(/^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if(res == null) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
    },
    computed: {
        user_role: {
            get: function () {
                return this.$store.getters.roles;
            },
            set: function (value) {
                this.$store.commit('changeUserRole', value);
            }
        },
        pastTab: {
            get: function () {
                return this.$store.getters.past_tab;
            },
            set: function (value) {
                this.$store.commit('changePastTab', value);
            }
        },
    }
};